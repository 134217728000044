:root{
  /* --fuentePrincipal: 'Roboto', sans-serif;
  --fuenteSecundaria: 'Lato', sans-serif; */

  --black: #1F1F1F;
  --white: #ffffff;
  --primary: #87124B;
  --secondary: #460826;
  --grey: #c3c3c3;
}

html{
  box-sizing: border-box;
  font-size: 62.5%;
}
*,*:before, *:after{
  box-sizing: inherit;
}

body{
  font-family: 'Noto Sans Display', sans-serif;;
  font-size: 1.6rem;
  line-height: 1;
}

/* Globales */
.container{
  margin: 0 auto;
  max-width: 120rem;
  width: 90%;
}

h1,h2,h3,h4,p{
  margin: 0;
  padding: 0;
}

img{
  display: block;
  width: 100%;
}

a{
  cursor: pointer;
}

/* Swipper styles */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet{
  width: 15px!important;
  height: 15px!important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: var(--primary)!important;
  width: 10px;
  height: 10px;
}